import React, { useEffect } from "react";
import Layout from "./components/Layout";
import Header from "./components/Header";
import LandingPage from "./components/LandingPage/LandingPage";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SupportPage from "./components/SupportPage/SupportPage";
import PrivacyPolicy from "./components/SupportPage/PrivacyPolicy";
import CommunityGuidelines from "./components/SupportPage/CommunityGuidelines";
import { userContext } from "./components/context/userContext";
import useToken from "./useToken";
import "./styles/App.scss";
import ReactGA from "react-ga4";
import { GA4_ID } from "./constants";
import ReferralTOS from './components/SupportPage/ReferralTOS';

const useVhListener = () => {
  const setVh = () => {
    // Calculate the correct viewport height (cross-browser)
    const vh =
      Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      ) * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  useEffect(() => {
    // Set the initial vh value
    setVh();

    // Add event listener to update vh on resize
    window.addEventListener("resize", setVh);

    // Clean up event listener when component unmounts
    return () => window.removeEventListener("resize", setVh);
  }, []);
};

ReactGA.initialize([
  {
    trackingId: GA4_ID,
  },
]);

function App() {
  const { token, setToken, deleteToken } = useToken();
  useVhListener();

  return (
    <userContext.Provider value={{ token, setToken, deleteToken }}>
      <Layout pageTitle="Download Grid, match by place or event in New York City">
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/support" element={<SupportPage />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/community" element={<CommunityGuidelines />} />
            <Route path="/tos/gridreferral" element={<ReferralTOS />} />
          </Routes>
        </BrowserRouter>
      </Layout>
    </userContext.Provider>
  );
}

export default App;
