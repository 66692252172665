import React from "react";
import "../../styles/ReferralTOS.scss"

const ReferralTOS = () => {
  return (
    <div className="referral-tos-wrapper">
      <div className="referral-tos-content">
        <h1 className="referral-tos-title">Grid User Referral Contest Program</h1>
        <h2 className="section-title">Terms and Conditions</h2>
        
        <h3 className="section-title">1. Eligibility:</h3>
        <div className="section">
          <p>- In order to participate in a Grid User Referral Contest ("Contest"), a participant must be a "Registered User".</p>
          <p>- A Registered User is a user of the Grid: Dating App who prior to the termination of the Contest is:</p>
          <ul>
            <li>18 years of age or older</li>
            <li>Has fully registered on the app</li>
            <li>Has created and completed a profile on the app</li>
            <li>Is in the New York Metropolitan area</li>
            <li>Has an account and profile verified as legitimate by the Grid team as abiding by the Privacy and Policy and Community Guidelines of Grid</li>
          </ul>
          <p>- A Contest shall be open to Registered Users of Grid who are legal residents of the States of New York, New Jersey or Connecticut, except employees of Grid and their immediate family members.</p>
          <p>- By participating in a Contest, the participant agrees to abide by these Terms & Conditions and all decisions of the Grid team, which shall be final and binding in all respects.</p>
          <p>- By participating in the Contest, the participant represents and warrants that the information that they submitted on the app or that will be submitted to Grid in connection with the Contest is true, correct, and complete in all material respects and is not misleading. Any misrepresentation or abuse of account registration may disqualify the participant from the Contest.</p>
        </div>

        <h3 className="section-title">2. Contest Period:</h3>
        <div className="section">
          <p>- Each Contest shall begin on a specified date and time (the "Contest Period").</p>
          <p>- Referrals made before or after the Contest Period will not be eligible for consideration for the Prize.</p>
        </div>

        <h3 className="section-title">3. How to Participate:</h3>
        <div className="section">
          <p>- To participate, a Registered User must refer potential new users to the Grid: Dating App during the Contest Period AND the potential user must:</p>
          <ul>
            <li>Fully register on the app</li>
            <li>Create and complete a profile on the app</li>
            <li>Be in the New York Metropolitan area</li>
            <li>Have their account and profile verified by the Grid team as being legitimate</li>
          </ul>
          <p>- Once the referral has been verified by the Grid team, the referral will be deemed as a qualified referral for purposes of the Contest.</p>
        </div>

        <h3 className="section-title">4. Prize:</h3>
        <div className="section">
          <p>- The participant who refers the largest number of new referred users during the Contest Period will be eligible to win $200 (the "Prize").</p>
          <p>- In the event of a tie, the Prize will be awarded to each participant of the tie.</p>
          <p>- The winner will be notified via email within seven (7) days of the end of the Contest Period.</p>
          <p>- The winner must respond to the notification email from Grid within fourteen (14) days to claim the Prize.</p>
          <p>- Failure by the participant to respond within the specified 14-day period will result in disqualification of that participant and selection of an alternate winner.</p>
        </div>

        <h3 className="section-title">5. Personal Information and Publicity:</h3>
        <div className="section">
          <p>- Personal information collected from participants in connection with this Contest is subject to Grid’s Privacy Policy, available at https://www.dateongrid.com/privacy. Personal information may be used by Grid in order to verify the participant’s identity, postal address, device location, and telephone number, and to otherwise verify the participant’s eligibility to participate in the Contest.</p>
          <p>- By participating in a Contest, participant agrees to allow the Company to use their name, likeness, and testimonial for promotional purposes without further compensation to participant, except where prohibited by law.</p>
          <p>- Participant agrees to receive promotional materials, including but not limited to blogs, articles, specials, and informational emails in relation to Grid. Participant may access, review, rectify, or cancel any personal information held by Grid, and/or elect to have their name excluded from all lists used by Grid, by emailing contact@dateongrid.com.</p>
        </div>

        <h3 className="section-title">6. General Conditions:</h3>
        <div className="section">
          <p>- The Company reserves the right to disqualify any participant found to be tampering with the Contest process or violating these Terms & Conditions.</p>
          <p>- The Company reserves the right, at its reasonable disclosure, to cancel, terminate, suspend, or modify the Contest if any fraud, technical failures, or other factor(s) beyond the Company's control impair the integrity or proper functioning of the Contest. In such case, and if possible, the Company may select the winner from all eligible entries received prior to the occurrence of the interfering event.</p>
          <p>- By participating in the Contest, participants agree to be bound by these Terms & Conditions.</p>
          <p>- By participating in the Contest, participant releases and holds harmless the Company and its officers, employees, and representatives from any and all liability arising from or related to the Contest or the Prize.</p>
          <p>- These Terms & Conditions are governed by the laws of the State of New York, United States, without regard to its conflict of law principles.</p>
        </div>

        <h2 className="section-title">Contact</h2>
        <div className="section">
          <p>For questions or clarifications regarding the Contest, please contact: contact@dateongrid.com</p>
        </div>
      </div>
    </div>
  );
};

export default ReferralTOS;
