import React from 'react';
import { Helmet } from "react-helmet";

const Layout = (props: any) => {
    return (
        <>
            {/* @ts-ignore */}
            <Helmet>
                <title>{props.pageTitle}</title>
                <meta charSet="UTF-8" />
                <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
                <link
                    rel="stylesheet"
                    href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
                />
            </Helmet>
            <div className="layout">
                {props.children}
            </div>
        </>
    )
}
export default Layout;