import React, { useState} from "react";
import "../../styles/SupportPage.scss";
import { Button, Form, FormGroup, Input } from "reactstrap";
import { getUrl } from "../utils/config";


const SupportPageForm = () => {
    const Statuses = {
        success: "success",
        failure: "failure",
        empty: "empty",
        invalid: "invalid",
      };
    
      const [email, setEmail] = useState("");
      const [name, setName] = useState("");
      const [message, setMessage] = useState("");
      const [isClicked, setIsClicked] = useState(false);
      const [status, setStatus] = useState(Statuses.empty);
    
      const clearFields = () => {
        setEmail("")
        setName("")
        setMessage("")
      }
    
      const handleSubmit = async (e: any) => {
        e.preventDefault();
    
        setIsClicked(true);
        setTimeout(() => setIsClicked(false), 200); // Reset the state after 200ms
    
        if (name == "" || email == "" || message == "") {
          setStatus(Statuses.invalid);
        } else {
          const addUser = async () => {
            try {
              const res = await fetch(
                getUrl(process.env.REACT_APP_API_URL, "support/ticket"),
                {
                  body: JSON.stringify({
                    name: name,
                    email: email,
                    message: message,
                  }),
                  headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                  },
                  method: "POST",
                }
              );
              return res.json();
            } catch (e: any) {
              console.error("failed to fetch" + e);
            }
          };
          try {
          const result = await addUser();
          if (result?.status == "success") {
            clearFields()
            setStatus(Statuses.success);
          } else {
            console.log(result);
            setStatus(Statuses.failure);
          }
          } catch (e) {
            console.log(e);
            setStatus(Statuses.failure);
          }
         
        }
        setTimeout(() => setStatus(Statuses.empty), 10000);
      };
    
      const handleEmailChange = (e: any) => {
        setEmail(e.target.value);
      };
    
      const handleNameChange = (e: any) => {
        setName(e.target.value);
      };
    
      const handleMessageChange = (e: any) => {
        setMessage(e.target.value);
      };
    
      const Feedback = () => {
        if (status === Statuses.failure) {
          return (
            <p className="signup-form-status signup-form-failure">
              Oops, went wrong. Please make sure your information is correct.
            </p>
          );
        } else if (status === Statuses.success) {
          return (
            <p className="signup-form-status signup-form-success">
              Support ticket submitted, check your email for updates!
            </p>
          );
        } else if (status === Statuses.invalid) {
          return (
            <p className="signup-form-status">
              Looks like you missed something, please make sure you've added the
              correct fields and try again
            </p>
          );
        } else {
          return <p />;
        }
      };

    return(
      <div className="support-page-form-wrapper"> 
        <Form className="support-page-form" onSubmit={handleSubmit}>
          <FormGroup className="support-page-form-group">
            <Input
              className="signup-form-input"
              type="text"
              name="name"
              id="name"
              onChange={handleNameChange}
              value={name}
              placeholder="Name"
            />

            <Input
              className="signup-form-input"
              type="email"
              name="email"
              id="email"
              onChange={handleEmailChange}
              value={email}
              placeholder="Email"
            />

            <Input
              className="signup-form-input"
              type="textarea"
              name="message"
              id="message"
              onChange={handleMessageChange}
              value={message}
              placeholder="Message"
            />
          </FormGroup>
          <Button
            className={`signup-form-button ${isClicked ? "clicked" : ""}`}
            type="submit"
          >
            Send
          </Button>
          <Feedback />
        </Form>
      </div>
    );
};
export default SupportPageForm;